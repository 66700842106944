import * as actionTypes from './../actions/actionTypes';
import { produce } from "immer";

const initialState = {
    inProgress: false,
    data: [],
    documentsMaxFileSize: false
};



const druidReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.druid.SCAN_DOCUMENT: {
                draft.inProgress = true;
                draft.data = []
                break;
            }
            case actionTypes.druid.SCAN_COMPLETE: {
                draft.inProgress = false;
                if (action.result.data) {

                    draft.data = {...action.result.data,...action.result.info};
                } else {
                    draft.data = {ocr_success_status:false};

                }
                break;
            }
            case actionTypes.druid.MAX_FILE_SIZE_RECEIVED: {
                draft.documentsMaxFileSize = action.size;
            }

            default:
                break;
        }
    });

export default druidReducer;
